import "aos/dist/aos.css";
import * as AOS from "aos/dist/aos";
import "./src/styles/main.scss";
import "./src/styles/tailwind.css";

window.dataLayer = window.dataLayer || [];
function gtag(...args) {
  window.dataLayer.push(arguments);
}

export const onClientEntry = () => {
  AOS.init({
    once: true,
    offset: 400,
  });

  gtag("consent", "default", {
    functionality_storage: "denied",
    personalization_storage: "denied",
    ad_storage: "denied",
    analytics_storage: "denied",
    wait_for_update: 2000, // milliseconds
    custom: true,
  });
};
